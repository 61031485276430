/* eslint-disable no-unused-vars */
/* jshint esversion: 6 */
/* eslint-disable prettier/prettier */
/* axios封装 */

import { closeToast, showToast } from "vant";

// http request 请求拦截
axios.interceptors.request.use(
	(config) => {
		if (import.meta.env.MODE === "development") {
			if (config.method === "post") console.log("axios-params: ", config.data);
			else console.log("axios-params: ", config.params);
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// 响应拦截 异常处理
axios.interceptors.response.use(
	(response) => {
		if (response.data.code != 200) {
			closeToast();
		}
		return response.data;
	},
	(error) => {
		closeToast();
		if (error && error.response) {
			console.log("error.response.status", error.response.status);
			switch (error.response.status) {
				case 400:
					showToast({ message: "错误请求", type: "fail" });
					break;
				case 401:
					showToast({ message: "错误请求", type: "fail" });
					break;
				case 403:
					showToast({ message: "拒绝访问", type: "fail" });
					break;
				case 404:
					showToast({ message: "请求错误,未找到资源", type: "fail" });
					break;
				case 405:
					showToast({ message: "请求方法未允许", type: "fail" });
					break;
				case 408:
					showToast({ message: "请求超时", type: "fail" });
					break;
				case 500:
					showToast({ message: "服务器端出错", type: "fail" });
					break;
				case 501:
					showToast({ message: "网络未实现", type: "fail" });
					break;
				case 502:
					showToast({ message: "网络错误", type: "fail" });
					break;
				case 503:
					showToast({ message: "服务不可用", type: "fail" });
					break;
				case 504:
					showToast({ message: "网络超时", type: "fail" });
					break;
				case 505:
					showToast({ message: "http版本不支持该请求", type: "fail" });
					break;
				default:
					showToast({ message: "连接错误", type: "fail" });
			}
		} else {
			showToast({ message: "连接到服务器失败", type: "fail" });
		}
		throw error.response;
	}
);

/**
 * post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url: string, data: any = {}) {
	return new Promise((resolve, reject) => {
		axios.post(import.meta.env.VITE_APP_API_URL + url, data).then(
			(response: any) => {
				if (response.code == 200) {
					resolve(response.data);
				} else {
					reject(response);
				}
			},
			(err) => {
				reject(err);
			}
		);
	});
}

/**
 * get 请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function fetch(url: string) {
	return new Promise((resolve, reject) => {
		axios.get(import.meta.env.VITE_APP_ALIOSS_URL + url).then(
			(response: any) => {
				resolve(response);
			},
			(err) => {
				reject(err);
			}
		);
	});
}
