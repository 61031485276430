import { post } from "@/utils/http";
import foodJson from "../utils/foodJson";

/**
 * 预约 reserve
 * 预约的ID
 */

interface itemDataType {
	name: string;
	phone: string;
	areaStr: string;
	production1?: string;
	production2?: string;
}

export const update = (params: itemDataType) => {
	return post("/update", params);
};

// 获取介绍页内容
export const getIntroJson = () => {
	return new Promise((resolve, reject) => {
		resolve(
			foodJson.filter((item) => {
				return item.show == 1;
			})
		);
	});
};

// 获取微信sign
export const getWxSign = (url: string) => {
	return post("/getWxSgin", { url });
};
