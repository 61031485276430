export default [
	{
		show: 1,
		id: 1,
		type: 1,
		name: "明太子焗扇贝",
		time: "7mins",
		text: "以玉米粒铺底，放上饱满的扇贝肉，顶部铺满明太子酱，烤制过程中，明太子酱铺满整个扇贝，鲜香的扇贝搭配甜甜的玉米粒，口感极佳。",
	},
	{
		show: 1,
		id: 2,
		type: 1,
		name: "京葱烧鸟串",
		time: "10mins",
		text: "烧鸟店的招牌之一，采用盐烤的方式，保留了鸡肉的原汁原味。明火烤制，焦香鲜嫩。",
	},
	{
		show: 1,
		id: 3,
		type: 1,
		name: "焦糖烤菠萝片",
		time: "10mins",
		text: "将菠萝切片，用黄油和糖烤至而成。表面焦化产生焦糖香味，中和了菠萝的酸味，烤后的菠萝也没有了涩感，美味多汁。",
	},
	{
		show: 1,
		id: 4,
		type: 1,
		name: "西班牙海鲜饭",
		time: "33mins",
		text: "西餐中的三大名菜之一，米饭中吸饱海鲜的汤汁，聚会的最后来一盘，足以惊艳全场。",
	},
	{
		show: 1,
		id: 5,
		type: 1,
		name: "红酒炖牛肉",
		time: "33mins",
		text: "由经典法国菜改良而成的家庭版，以番茄、红酒、蔬菜为基底，加入薄切的牛腱肉，全程不加一滴水原汁原味。",
	},
	{
		show: 1,
		id: 6,
		type: 1,
		name: "酒蒸蛤蜊",
		time: "5mins",
		text: "《深夜食堂》的经典小菜，简单的食材，仅仅几分钟就能做成鲜甜多汁的酒蒸蛤蜊，快手便捷的下酒好菜。",
	},
	{
		show: 1,
		id: 7,
		type: 1,
		name: "Mini巴斯克",
		time: "10mins",
		text: "以奶油奶酪、淡奶油、鸡蛋、砂糖混合而成的一种芝士蛋糕，经由高温烤至表面焦褐、内心柔软为最佳状态，冷藏食用口感绵密顺滑。",
	},
	{
		show: 1,
		id: 8,
		type: 2,
		name: "芝士焗大虾",
		time: "5mins",
		text: "精选6头的黑虎大虾，开背加入白汁酱，烤制微微上色，取出时表面冒泡非常治愈，芝香四溢，绝对是聚餐桌上的一道硬菜。",
	},
	{
		show: 1,
		id: 9,
		type: 2,
		name: "川味烤鱼",
		time: "23mins",
		text: "以鲈鱼为主食材、烤至鱼皮微脆，加入特调川味料汁，炖煮入味，香辣鲜嫩。",
	},
	{
		show: 1,
		id: 10,
		type: 2,
		name: "鲜虾饼",
		time: "5mins",
		text: "用鲜虾仁加入辅料搅打制成虾饼，烤至底部金黄，一口咬下，鲜嫩多汁。",
	},
	{
		show: 1,
		id: 11,
		type: 2,
		name: "芝士烤番茄",
		time: "10mins",
		text: "将培根土豆泥酿入番茄，表面铺上芝士烤至而成，出炉后刨上帕玛森芝士碎，别有一番风味。",
	},
	{
		show: 1,
		id: 12,
		type: 2,
		name: "客家酿豆腐",
		time: "15mins",
		text: "将调味后的猪肉馅酿入卤水豆腐中煎烤，最后淋入特调酱汁，表皮微焦，内里鲜嫩，鲜香入味。",
	},
	{
		show: 1,
		id: 13,
		type: 2,
		name: "烤羊排",
		time: "8mins",
		text: "以法式羊排为原材料，用橄榄油进行煎烤，外酥里嫩伴着淡淡的迷迭香的香气，一口鲜嫩。",
	},
	{
		show: 1,
		id: 14,
		type: 2,
		name: "黄油酱烤杏鲍菇",
		time: "8mins",
		text: "薄薄的杏鲍菇片伴着黄油的香气，表面微焦，入口脆嫩，比肉菜还好吃。",
	},
	{
		show: 1,
		id: 15,
		type: 2,
		name: "盐焗乳鸽",
		time: "40mins",
		text: "将乳鸽洗净，以盐焗鸡粉入味，肚内塞入花椒增香，以粗盐焗烤的方式制成。撕开鸽身，汁水四溢，鲜嫩味美。",
	},
	{
		show: 1,
		id: 16,
		type: 2,
		name: "辣子鸡",
		time: "13mins",
		text: "以掌中宝为原材料，用煎烤的方式代替油炸，脆脆的掌中宝带着辣椒的香气，微辣微麻恰到好处。",
	},
	{
		show: 1,
		id: 17,
		type: 2,
		name: "孜然羊肉",
		time: "7mins",
		text: "选用羊腿或羊里脊为主食材，搭配洋葱、香菜、孜然等辅料，鲜嫩无膻味，营养丰富，配饭配酒都美味。",
	},
	{
		show: 1,
		id: 18,
		type: 2,
		name: "蒜香骨",
		time: "13mins",
		text: "明火烤制的蒜香骨，表皮酥脆，一口爆汁。对比传统油炸，更健康便捷。",
	},
	{
		show: 1,
		id: 19,
		type: 2,
		name: "明太子烤山药",
		time: "7mins",
		text: "传统日式做法，将山药生磨成泥，加入明太子打散后烤制，出炉后加入可生食蛋黄和海苔碎搅匀食用，搭配米饭即可成为明太子山药丼。",
	},
	{
		show: 1,
		id: 20,
		type: 2,
		name: "芝士玉米粒",
		time: "7mins",
		text: "以玉米粒、双重芝士、色拉酱为主的一道菜，烤至表面金黄，口感湿润，芝香奶香，超长拉丝。",
	},
	{
		show: 1,
		id: 21,
		type: 2,
		name: "日式咖喱海鲜",
		time: "30mins",
		text: "六种海鲜、四种蔬菜搭配咖喱块炖煮而成，30分钟就能做出营养美味的日式咖喱海鲜。",
	},
	{
		show: 1,
		id: 22,
		type: 2,
		name: "茄汁龙利鱼",
		time: "15mins",
		text: "浓郁的茄汁包裹着龙利鱼块，酸甜开胃，鲜嫩又没有鱼刺，拌饭吃超级美味。",
	},
	{
		show: 1,
		id: 23,
		type: 2,
		name: "奶油烩蔬菜",
		time: "10mins",
		text: "选择根茎类蔬菜，加入黄油、奶油、牛奶炖煮而成，简单的蔬菜奶香味十足，一口下去暖胃暖心。",
	},
	{
		show: 1,
		id: 24,
		type: 2,
		name: "全家福",
		time: "20mins",
		text: "各地年菜桌上都少不了一份当地的“全家福”、菜品多样、营养丰富、寓意美好，可以说是年菜的标配。",
	},
	{
		show: 1,
		id: 25,
		type: 2,
		name: "一锅双菜（肥肠煲+蒜蓉丝瓜）",
		time: "15mins",
		text: "一边是咸香四溢、入口软烂的肥肠煲，另一边是简单美味的蒜蓉丝瓜，搭配一碗米饭，简单的一人食即可享用。",
	},
	{
		show: 1,
		id: 26,
		type: 2,
		name: "烤生蚝",
		time: "13mins",
		text: "肥美的生蚝，用蒜蓉酱简单烹调就很美味。菜谱采用提前蒸制的方式，可以保证蚝肉肥嫩的同时更简单的开壳。",
	},
	{
		show: 1,
		id: 27,
		type: 2,
		name: "铜锣烧",
		time: "6mins",
		text: "由两片铜锣状蛋糕饼皮与各类夹心组成的甜品，以红豆馅为经典口味。是日本人喜爱的糕点之一，因是日本卡通人物多啦A梦最爱的食物而被大家熟知。",
	},
	{
		show: 1,
		id: 28,
		type: 2,
		name: "奶油布丁烤吐司",
		time: "30mins",
		text: "简单的吐司片与双色牛奶混合烤制而成的双色布丁吐司。因吐司吸收了大量牛奶，口感类似布丁，简单又美味。",
	},
	{
		show: 1,
		id: 29,
		type: 2,
		name: "蛋挞",
		time: "10mins",
		text: "采用葡式蛋挞的制作方式，层层酥皮包裹着奶香味十足的奶蛋液，表面微焦香气宜人。趁热吃上一口，外酥里嫩、香甜可口，是下午茶的不二之选。",
	},
	{
		show: 1,
		id: 30,
		type: 2,
		name: "芝士焗红薯泥",
		time: "40mins",
		text: "将红薯烤熟后捣成泥、拌入马苏里拉芝士碎、淡奶油、黄油等食材，烤制之后舀起一勺，香甜软糯还能拉丝，满满一口都是幸福的滋味。",
	},
];
