import { defineStore } from "pinia";
import { getIntroJson } from "@/api";
import _array from "lodash/array";
// interface userInfoType {
// 	id: number | string;
// 	phone: string;
// 	nickName: string;
// 	actionIds: string;
// 	sectionIds: string;
// 	sectionTimeIds: string;
// }

export default defineStore("foods", {
	state: (): {
		foods: any;
	} => {
		return {
			foods: [],
		};
	},

	getters: {
		starFoods: (state) => {
			return state.foods.filter((item) => item.type == 1);
		},
		normalFoods: (state) => {
			return _array.chunk(
				state.foods.filter((item) => item.type == 2),
				4
			);
		},
	},

	actions: {
		getFoods() {
			getIntroJson().then((res) => {
				this.foods = res;
			});
		},
	},
});
