<template>
	<div :class="$style['main-section']">
		<router-view />
	</div>
</template>

<script setup lang="ts">
	import { getWxSign } from "@/api/index";
	import { useRoute } from "vue-router";
	import useBaseInfo from "@/store/baseInfo";

	const baseInfo = useBaseInfo();

	baseInfo.getFoods();
	const blackLogoRoute = ["dayPage"];

	const curRouteName: any = computed(() => {
		const routename: any = useRoute().name;
		return blackLogoRoute.indexOf(routename) < 0;
	});

	const shareurl = window.location.href.split("#")[0];
	console.log("%c -----> 001 shareurl", "font-size:13px; background:pink; color:#bf2c9f;", shareurl);
	const shareConfig = {
		title: "能率(中国)30周年庆典暨新品发布会",
		desc: "",
		link: shareurl,
		imgUrl: "http://30th.noritz.com.cn/noritzshare.png",
	};

	getWxSign(shareurl).then((res: any) => {
		wx.config({
			debug: false,
			appId: "wxa04ef200378f33a4",
			timestamp: res.timestamp,
			nonceStr: res.nonceStr,
			signature: res.signature,
			jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline", "updateTimelineShareData", "updateAppMessageShareData"],
		});
		wx.ready(() => {
			wx.onMenuShareAppMessage(shareConfig);
			wx.onMenuShareTimeline(shareConfig);
			wx.updateTimelineShareData(shareConfig);
			wx.updateAppMessageShareData(shareConfig);
		});
	});
</script>

<style lang="scss" module>
	.main-section {
		min-height: 100vh;
		position: relative;
		background-color: white;
	}

	.logo-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 2;
		height: 1.6rem;
		font-size: 0;
		text-align: center;
		display: flex;
		align-items: end;
		justify-content: center;
		padding: 0 1.4rem;
		img {
			max-width: 6.4rem;
		}
	}
</style>
