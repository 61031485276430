import "./assets/global.scss";

import App from "./App.vue";
const app3 = createApp(App);

import initRegist from "@/utils/initRegist";
initRegist(app3);
import router from "./router";

app3.use(router).mount("#app");
