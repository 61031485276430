import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "kv",
		component: () => import(/* @vite-ignore */ "../views/KvPage.vue"),
	},
	{
		path: "/home",
		component: () => import(/* @vite-ignore */ "../views/HomePage.vue"),
		children: [
			{
				path: "",
				name: "intro",
				component: () => import(/* @vite-ignore */ "../views/Intro.vue"),
			},
			{
				path: "/reserve",
				name: "reserve",
				component: () => import(/* @vite-ignore */ "../views/Reserve.vue"),
			},
			{
				path: "/guide",
				name: "guide",
				component: () => import(/* @vite-ignore */ "../views/Guide.vue"),
			},
			{
				path: "/production",
				name: "production",
				component: () => import(/* @vite-ignore */ "../views/Production.vue"),
			},
			{
				path: "/detail",
				name: "detail",
				component: () => import(/* @vite-ignore */ "../views/Detail.vue"),
			},
		],
	},
];

const router = createRouter({
	history: createWebHashHistory(""),
	routes,
});

router.beforeEach((to, from, next) => {
	// 兼容chrome
	document.body.scrollTop = 0;
	// 兼容firefox
	document.documentElement.scrollTop = 0;
	// 兼容safari
	window.pageYOffset = 0;
	next();
});

export default router;
